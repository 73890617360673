import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { initialState } from './state';

@Injectable({
	providedIn: 'root',
})
export class AccessDeniedService {
	state$ = new BehaviorSubject(initialState);

	private state = initialState;

	redirectFromModule(moduleName: string) {
		this.state = {
			...this.state,
			moduleName,
			redirected: true,
		};

		this.state$.next(this.state);
	}
}
